import * as React from "react";
import { Goal, GoalWithChildMap, Section, TextTocBullet } from "../GoalMap/types";
import { Block, MarkDef, MarkDefLink, Maybe } from "../../types/graphql";
import { convertEP3DocumentToSection, hasCategory, hasNoChildren, isGoalWithChildMap } from "../GoalMap/utils";
import { reformatSlug } from "../../utils";
import { DynamicComponent } from "../DynamicComponent";
import { pipe } from "fp-ts/lib/function";
import * as A from "fp-ts/lib/Array";
import * as O from "fp-ts/lib/Option";
import { TOCPageProps, WithHowToUsePage } from "../../pages/guide-overview";
import { Show } from "../Show";
import { LeaderLineTOC } from "./LeaderLine";

const camelToSpaceSeparated = (str: string) => {
  if (str.length < 2) {
    return str?.toUpperCase();
  }
  return str[0]?.toUpperCase() + str.slice(1).replace(/[a-z][A-Z]+/g, (x) => x[0] + " " + x.slice(1));
};

const createSubtreeInCategory =
  (category: string) =>
    (name: string): GoalWithChildMap => ({
      __typename: "SanityGoal",
      category,
      name: camelToSpaceSeparated(name),
      children: {},
      importance: 2,
      goalMapName: null,
    });

const addCategoryObjectChild =
  (acc: GoalWithChildMap["children"], category: string, categoryObject: GoalWithChildMap) =>
    (child: Section | GoalWithChildMap): GoalWithChildMap["children"] => {
      const children = { ...categoryObject.children, [child.name]: child };
      return {
        ...acc,
        [category]: { ...categoryObject, children },
      };
    };

const createTableOfContents = (sections: Section[]) => {
  return sections.reduce(
    (
      acc,
      { category, subcategory, name, slug, publicationStatus, pageResources, goalMapName, __typename }
    ): GoalWithChildMap["children"] => {
      const linkProps: Section = {
        name,
        slug,
        category,
        subcategory,
        importance: 3,
        publicationStatus,
        pageResources,
        goalMapName,
        __typename,
      };
      const categoryObject = acc[category] || createSubtreeInCategory(category)(category);
      if (!isGoalWithChildMap(categoryObject)) {
        return acc;
      }
      const appendCategoryObjectChild = addCategoryObjectChild(acc, category, categoryObject);

      if (subcategory == null) {
        return appendCategoryObjectChild(linkProps);
      }
      const subcategoryObject =
        categoryObject.children[camelToSpaceSeparated(subcategory)] || createSubtreeInCategory(category)(subcategory);
      if (!isGoalWithChildMap(subcategoryObject)) {
        // this should never happen because we cannot add a Section as a child of a Section
        return appendCategoryObjectChild(linkProps);
      }

      return appendCategoryObjectChild({
        ...subcategoryObject,
        children: {
          ...subcategoryObject.children,
          [linkProps.name]: linkProps,
        },
      });
    },
    {} as GoalWithChildMap["children"]
  );
};

export const createTextToc = (tree: Maybe<TextTocBullet & { pageResources: any }>, level = 1): Block[] => {
  if (tree == null) {
    return [];
  }
  const _key = `${tree.name}_${level}`;
  const markKey = `markKey_${_key}`;
  const markDefs: MarkDef[] = hasNoChildren(tree)
    ? [
      {
        _key: markKey,
        _type: "tocLink",
        href: tree.slug?.current ?? "",
      } as MarkDefLink,
    ]
    : [];

  const marks = hasNoChildren(tree) ? [markKey] : [];

  const resourcesBlock =
    hasNoChildren(tree) && tree.pageResources?.length > 0
      ? [
        {
          _key: `${_key}_resources`,
          _type: "pageResourceList" as const,
          children: tree.pageResources,
        },
      ]
      : [];

  const block: Block = {
    _key,
    _type: "block",
    level,
    listItem: "number",
    children: [
      {
        _key: `${_key}_child`,
        _type: "span",
        marks,
        text: tree.name,
      },
      ...(resourcesBlock as any),
    ],
    markDefs,
    style: "normal",
  };

  return hasNoChildren(tree)
    ? [block]
    : [
      block,
      ...(tree.children ?? []).reduce((acc, child): Block[] => {
        return [...acc, ...createTextToc(child as any, level + 1)];
      }, [] as Block[]),
    ];
};

const convertChildMapToArray = (goal: GoalWithChildMap["children"]): Goal["children"] => {
  return Object.values(goal).reduce((acc, child): (Goal | Section)[] => {
    return [...acc, isGoalWithChildMap(child) ? { ...child, children: convertChildMapToArray(child.children) } : child];
  }, [] as (Goal | Section)[]);
};

export const VisualTOC = ({ data }: TOCPageProps<WithHowToUsePage>) => {
  const [activeTab, setActiveTab] = React.useState(1);
  const vToc = data?.sanityVisualToc;
  const { effectivePractices, resources } = vToc ?? {}
  React.useEffect(() => {
    const cachedRef = localStorage.getItem("visual_toc_tab_selection");
    const isMobile =
      Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
    setActiveTab((x) => (cachedRef && parseInt(cachedRef)) || (isMobile && x) || 0);
  }, []);

  const onTabClick = (index: number) => () => {
    localStorage.setItem("visual_toc_tab_selection", `${index}`);
    setActiveTab(index);
  };

  const ActiveClasses = "text-primary border-primary";
  const TabClasses = "no-bullet cursor-pointer py-2 px-4 text-gray-500 border-b-8 !ml-0";

  const displayTextToc = data?.sanityVisualToc?.resources != null;
  if (effectivePractices == null) {
    return <p className="text-3xl text-center py-8 bg-red-500 text-white">Something went wrong</p>;
  }

  const nodes = effectivePractices.filter(hasCategory);

  const resourceNodes = displayTextToc
    ? createTextToc({
      __typename: "NonSanityResources",
      name: "Resources",
      children: resources?.map((r): TextTocBullet => (hasNoChildren(r) ? reformatSlug(r) : r)),
    } as any)
    : [];

  const children = pipe(
    nodes,
    A.map(convertEP3DocumentToSection),
    createTableOfContents,
    convertChildMapToArray,
    O.fromNullable,
    O.getOrElse((): NonNullable<Goal["children"]> => [])
  );

  const visualTOCTree = {
    __typename: "SanityGoal" as const,
    importance: 0,
    name: "Effective Practices",
    children,
    goalMapName: null,
  };

  const textToc = displayTextToc
    ? [visualTOCTree].reduce((acc, node: TextTocBullet): Block[] => [...acc, ...createTextToc(node as any)], [] as Block[])
    : [];

  const howToUsePage = data.howToUsePage ? { ...data.howToUsePage, name: "How to Use the Guide" } : null;
  const textTocBlocks = [...createTextToc(howToUsePage as any), ...textToc, ...resourceNodes];
  return (
    <div className="w-screen">
      <ul className="my-4 !list-none !ml-0  hidden md:flex">
        <li key="tab-0" className={`${TabClasses} ${activeTab === 0 ? ActiveClasses : ""}`}>
          <button onClick={onTabClick(0)}>Visual</button>
        </li>
        <li key="tab-1" className={`${TabClasses} ${activeTab === 1 ? ActiveClasses : ""}`}>
          <button onClick={onTabClick(1)}>Textual</button>
        </li>
      </ul>

      <div className="bg-white container hidden md:block">
        <Show visible={activeTab === 0}>
          <LeaderLineTOC key="leaderline" data={children} />
        </Show>
        <Show visible={activeTab === 1}>
          <div className="underline">
            {/* <TableOfContentsHeader type="Textual" display={displayTextToc} /> */}
            <DynamicComponent blocks={textTocBlocks} />
          </div>
        </Show>
      </div>
      <div className="block md:hidden">
        <DynamicComponent blocks={textTocBlocks} />
      </div>
    </div>
  );
};
