import * as React from "react";
// @ts-ignore
import { snakeCase } from "change-case";
type Timeout = ReturnType<typeof setTimeout>;

const arrayToObj = (categories: any[]) => {
  return categories.reduce((acc: { [x: string]: any; }, item: { split: (arg0: string) => [any, ...any[]]; }) => {
    const [key, ...values] = item.split(".");
    const newList = arrayToObj(values)
    acc[key] = { ...acc[key], ...newList };
    return acc;
  }, {})
}

export const useLeaderLines = (data: { category: any; children: any; }[], COLOR_CODE: { [x: string]: { color: any; }; students?: { color: string; borderColor: string; textColor: string; }; curriculumAndPedagogy?: { color: string; borderColor: string; textColor: string; }; programs?: { color: string; borderColor: string; textColor: string; }; department?: { color: string; borderColor: string; textColor: string; }; }) => {
  const [lines, setLines] = React.useState<LeaderLine[]>([])

  const makeLine = React.useCallback((src, dest, options) => {
    if (!document.getElementById(src) || !document.getElementById(dest)) {
      return;
    }

    setLines(x => {
      x.push(new LeaderLine(
        document.getElementById(src)!,
        document.getElementById(dest)!,
        { endPlug: "behind", ...options }
      ));
      return x;
    })
    // to fix a reflow issue
    setTimeout(() => lines.map((l) => {
      try {
        l.position()
      } catch {
        // empty catch...
      }
    }), 10);
  }, []);
  const resizeCounter = React.useRef<null | undefined | Timeout>(null);
  React.useEffect(() => {
    const listener = () => {
      lines.forEach((l) => {
        try {
          l.remove()
        } catch (e) {
          //
        }
      })
      resizeCounter.current && clearTimeout(resizeCounter.current);
      resizeCounter.current = setTimeout(() => {
        if (window.innerWidth < 768) {
          return;
        }
        data.forEach(({ category, children }) => {
          makeLine("effective_practices", category, { color: COLOR_CODE[category].color })
          children.forEach((subcategory: { children: any[]; }) => {
            makeLine(category, `${category}.${subcategory.children[0].subcategory}`, { color: COLOR_CODE[category].color })
            subcategory.children.map((practice: { category: any; subcategory: any; name: string; }) => {
              makeLine(`${practice.category}.${practice.subcategory}`, `${practice.category}.${practice.subcategory}.${snakeCase(practice.name)}`, { color: COLOR_CODE[category].color })
            })
          })
        })
      }, 1000);
    };
    window.addEventListener("resize", listener, false);

    () => {
      window.removeEventListener("resize", listener, false);
    }
  })

  React.useLayoutEffect(() => {

    const timeout = setTimeout(() => {
      data.forEach(({ category, children }) => {
        makeLine("effective_practices", category, { color: COLOR_CODE[category].color })
        children.forEach((subcategory: { children: any[]; }) => {
          makeLine(category, `${category}.${subcategory.children[0].subcategory}`, { color: COLOR_CODE[category].color })
          subcategory.children.map((practice: { category: any; subcategory: any; name: string; }) => {
            makeLine(`${practice.category}.${practice.subcategory}`, `${practice.category}.${practice.subcategory}.${snakeCase(practice.name)}`, { color: COLOR_CODE[category].color })
          })
        })
      })
    }, 0);

    return () => {
      clearTimeout(timeout)
      lines.forEach((l) => {
        try {
          l.remove()
        } catch (e) {
          //
        }
      })
    }
  }, [])
}
