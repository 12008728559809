/* eslint-disable functional/immutable-data */
import * as React from "react";
import { graphql } from "gatsby";
import { BasePage, Maybe, SanityGlobalFooter } from "@/types/graphql";
import { EP3Document, TextTocBullet } from "@components/GoalMap/types";
import { Container } from "@components/Layout/Container";
import { Layout } from "@components/Layout";
import { BreadCrumbs } from "@components/Breadcrumbs";
import { heroAtom, seoAtom } from "@/store";
import { Header } from "@components/Header";
import { Title } from "@components/Header/Title";
import { Helmet } from "react-helmet";
import { DynamicComponent } from "@components/DynamicComponent";
import { VisualTOC } from "@components/VisualTOC";
import { useHydrateAtoms } from "jotai/utils";

export type TOCPageProps<AdditionalData = {}> = {
  data: AdditionalData &
  Maybe<{
    allNonVersionedDocuments?: any;
    footer?: Maybe<SanityGlobalFooter>;
    sanityVisualToc: Maybe<{ resources: Maybe<TextTocBullet[]>; effectivePractices: Maybe<EP3Document[]> }>;
    sanityPage: Maybe<BasePage & { heading: Maybe<string>, hero: any }>;
  }>;
};

export type WithHowToUsePage = {
  hero: any;
  howToUsePage?: Maybe<{
    name: Maybe<string>;
    publicationStatus: Maybe<string>;
    slug: Maybe<{ current: Maybe<string> }>;
    children: undefined;
    __typename: string;
  }>;
};

const SectionsPage = ({ data }: TOCPageProps<WithHowToUsePage>) => {
  useHydrateAtoms([
    [heroAtom, data?.sanityPage?.hero ?? data?.hero],
    [seoAtom, data?.sanityPage?.seo],
  ])
  return (
    <>
      <Helmet>
        <title> Guide Overview | EP3 Guide</title>
        <style>{`
          .leader-line {
            z-index: 100;
          }
        `}</style>
      </Helmet>
      <Layout>
        <Container>
          <Header>
            <BreadCrumbs paths={[{ url: "/", label: "Home" }]} title={"Guide Overview"} />
            <Title>Guide Overview</Title>
          </Header>
          <p className="w-full lg:w-3/4 mb-6 text-lg lg:text-xl">
            <DynamicComponent blocks={data?.sanityPage?._rawBody} />
          </p>
          <VisualTOC data={data} />


        </Container>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "hero-random-page.jpg" }) {
      ...heroImageFragment
    }

    sanityPage(slug: { current: { eq: "/guide-overview" } }) {
      _rawBody(resolveReferences: { maxDepth: 5 })
      hero {
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
        }
        _rawAsset(resolveReferences: { maxDepth: 10 })
        _rawCrop(resolveReferences: { maxDepth: 10 })
        _rawHotspot(resolveReferences: { maxDepth: 10 })
      }
    }

    sanityVisualToc {
      effectivePractices {
        __typename
        slug {
          current
        }
        publicationStatus
        name: heading
        category
        pageResources {
          slug {
            current
          }
          name: heading
          publicationStatus
        }

        effectivePractices {
          title
          actionablePractices {
            title
          }
        }
      }
      resources {
        slug {
          current
        }
        name: heading
        publicationStatus
      }
    }
  }
`;

export default SectionsPage;

type VisualTOC = any; // I really dont know the shape of this data.
