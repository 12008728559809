import * as React from "react";
import { useLeaderLines } from "@/hooks/useLeaderLines";
import { snakeCase } from "change-case";

const COLOR_CODE = {
  students: {
    color: "#ac207d",
    borderColor: "border-[#ac207d]",
    textColor: "text-[#ac207d]",
  },
  curriculumAndPedagogy: {
    color: "#4652a4",
    borderColor: "border-[#4652a4]",
    textColor: "text-[#4652a4]",
  },
  programs: {
    color: "#a4d044",
    borderColor: "border-[#a4d044]",
    textColor: "text-[#a4d044]",
  },
  department: {
    color: "#00b6fd",
    borderColor: "border-[#00b6fd]",
    textColor: "text-[#00b6fd]",
  },
};

const bubbleStyles = "w-32 xl:w-56 text-xl font-semibold text-center border-2 py-2 rounded";

export const LeaderLineTOC = ({ data }: { data: any }) => {
  useLeaderLines(data, COLOR_CODE);
  return (
    <div className="grid grid-cols-4 items-center justify-around gap-2 2xl:gap-10">
      <div className="flex items-center flex-col justify-center h-full">
        <div id="effective_practices" className={`${bubbleStyles}border-black`}>
          Effective Practices
        </div>
      </div>

      <div className="flex flex-col h-full items-stretch gap-10 [justify-content:inherit]">
        {data.map(
          (datum: {
            category: string;
            name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
          }) => {
            const colorPicker = COLOR_CODE[datum.category as keyof typeof COLOR_CODE] ?? {};
            return (
              <div
                key={datum.category}
                id={datum.category}
                className={`${bubbleStyles} ${colorPicker.borderColor} ${colorPicker.textColor}`}
              >
                {datum.name}
              </div>
            );
          }
        )}
      </div>

      <div className="flex flex-col h-full items-stretch gap-10 [justify-content:inherit]">
        {data.reduce((acc: any[], datum: { category: string | number; children: any[] }) => {
          const colorPicker = COLOR_CODE[datum.category as keyof typeof COLOR_CODE] ?? {};
          const tier2 = datum.children.map(
            (subValue: {
              children: { subcategory: any }[];
              name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined;
            }) => (
              <div
                key={`${datum.category}.${subValue.children[0].subcategory}`}
                id={`${datum.category}.${subValue.children[0].subcategory}`}
                className={`${bubbleStyles} ${colorPicker.borderColor} ${colorPicker.textColor}`}
              >
                {subValue.name}
              </div>
            )
          );
          tier2 && acc.push(tier2);
          return acc;
        }, [])}
      </div>

      <div className="flex items-left flex-col justify-evenly h-full gap-4">
        {data.reduce((acc: Element[], datum: { children: any[] }) => {
          return acc.concat(
            datum.children.reduce((innerAcc: Element[], subcategory: { children: any[] }) => {
              const practices = subcategory.children.map(
                (practice: {
                  category: string;
                  subcategory: string;
                  name: string;
                  publicationStatus: string;
                  slug: { current: string | undefined };
                }) => {
                  return (
                    <div
                      key={`${practice.category}.${practice.subcategory}.${snakeCase(practice.name)}`}
                      id={`${practice.category}.${practice.subcategory}.${snakeCase(practice.name)}`}
                      className="w-84 text-left pl-2 rounded"
                    >
                      {practice.publicationStatus === "live" ? (
                        <a
                          href={practice.slug?.current}
                          className="focus:underline hover:underline focus:text-blue-700 hover:text-blue-700"
                        >
                          {practice.name}
                        </a>
                      ) : (
                        <span className="text-slate-300">{practice.name}</span>
                      )}
                    </div>
                  );
                }
              );
              return innerAcc.concat(practices as any[]);
            }, [])
          );
        }, [])}
      </div>
    </div>
  );
};
