import { HierarchyPointNode } from "@visx/hierarchy/lib/types";
import {
  BulletWithPageInfo,
  Dimensions,
  EP3Document,
  Goal,
  GoalWithChildMap,
  SanityGoal,
  Section,
  TextTocBullet,
} from "./types";

// TYPE GUARDS
export const isEP3Document = (x: SanityGoal | EP3Document): x is EP3Document => x.__typename === "SanityEP3Document";

export const isSectionNode = (node: HierarchyPointNode<Goal | Section>): node is HierarchyPointNode<Section> =>
  isEP3Document(node.data);

export const isSection = <X extends { __typename: string }>(node: X | Section): node is Section =>
  node.__typename === "SanityEP3Document";

export const isGoalWithChildMap = (node: GoalWithChildMap | Section): node is GoalWithChildMap =>
  node.__typename === "SanityGoal";

export const hasNoChildren = (tree: TextTocBullet): tree is BulletWithPageInfo => tree.children == null;

// OTHER UTILS

export const hasCategory = ({ category }: Pick<EP3Document, "category">) => category != null;

export const convertEP3DocumentToSection = (ep3Document: EP3Document) => {
  const [category, subcategory] = ep3Document.category.split(".").map((x) => x ?? null);
  return { ...ep3Document, category, subcategory };
};

export const categoryToColor = (data: Section | Goal) => {
  const categoryToColorMap: Record<string, string> = {
    students: "text-pink",
    curriculumAndPedagogy: "text-primary",
    programs: "text-program-green",
    department: "text-department-cyan",
  };
  const color = data.category && categoryToColorMap[data.category];
  return color || "text-gray-800";
};

export const categoryToBackgroundColor = (category: string) => {
  const categoryToBackgroundColorMap: Record<string, string> = {
    students: "bg-pink",
    curriculumAndPedagogy: "bg-primary",
    programs: "bg-program-green",
    department: "bg-department-cyan",
  };
  const color = category && categoryToBackgroundColorMap[category];
  return color || "border-gray-800";
};

export const categoryToBorderColor = (category: string) => {
  const categoryToBorderColorMap: Record<string, string> = {
    students: "border-pink",
    curriculumAndPedagogy: "border-primary",
    programs: "border-program-green",
    department: "border-department-cyan",
  };
  const color = category && categoryToBorderColorMap[category];
  return color || "border-gray-800";
};

export const getDepth = (node: Goal | Section, currentDepth = 0): number => {
  if (isSection(node)) {
    return currentDepth;
  }
  const { children } = node;
  return children == null
    ? currentDepth
    : children.reduce((acc, childNode) => Math.max(acc, getDepth(childNode, currentDepth + 1)), currentDepth);
};

export const getTotalDeepestLeaves = (node: Goal | Section, maxDepth: number, totalLeaves = 0, depth = 0): number => {
  if (isSection(node)) {
    return depth === maxDepth ? 1 : 0;
  }
  return node.children == null
    ? 0
    : node.children.reduce(
      (acc, childNode) => acc + getTotalDeepestLeaves(childNode, maxDepth, totalLeaves, depth + 1),
      0
    );
};

export const getSize = ({ width, height, margin }: Dimensions): [Height: number, Width: number] => [
  height - margin.top - margin.bottom,
  width - margin.left - margin.right,
];

export const getOuterDimensions = (tree: Goal | Section, depthWidth: number) => {
  const depth = getDepth(tree);
  const width = depth * depthWidth;
  const height = getTotalDeepestLeaves(tree, depth) * 70;
  return { width, height, depth };
};

export const getOrElse = <T>(t: T | null | undefined, fallback: T): T => (t == null ? fallback : t);

export const sortAndBalance = <X>(arr: X[], sortBy: (a: X, b: X) => -1 | 1 | 0) => {
  return [...arr].sort(sortBy).reduce((acc: X[], x): X[] => {
    const copiedArr = [...acc];
    copiedArr.splice(Math.floor((copiedArr.length + 1) / 2), 0, x);
    return copiedArr;
  }, []);
};

export const sortAndBalanceChildren = (tree: Goal | Section): Goal | Section => {
  if (isSection(tree)) {
    return tree;
  }

  const children: (Section | Goal)[] = (tree.children || []).map((child) =>
    isSection(child) ? child : sortAndBalanceChildren(child)
  );

  return {
    ...tree,
    children: sortAndBalance(children, (a, b) => (getDepth(a) > getDepth(b) ? -1 : 1)),
  };
};
